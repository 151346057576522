.container {
  background-image: url("../images/pbmintbg.png");
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.main {
  position: absolute;
  overflow: hidden;
  top: 75px;
  bottom: 82px;
  width: 100%;

  .bossBear {
    margin: auto;

    margin-bottom: 2rem;
    @media (max-width: 690px) {
      // margin-bottom: 4.9rem;
    }
  }

  .imgContainer {
    max-width: 900px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -3rem;
    left: 50%;
    transform: translateX(-50%);
    @media (max-height: 950px), (max-width: 937px) {
      max-width: 750px;
      width: 750px;
    }
    @media (max-height: 845px) {
      max-width: 650px;
    }
    @media (max-height: 750px) {
      max-width: 575px;
    }
    @media (max-height: 706px) {
      max-width: 500px;
      width: 650px;
    }
  }

  .whitelistImgContainer {
    max-width: 600px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 8.2rem;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 690px) {
      max-width: 520px;
      bottom: 11rem;
    }
  }
}

.headerContainer {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;

  img {
    position: fixed;
    left: 34px;
    top: 11px;
  }
}

.logo {
  width: 179px;
  height: 117px;
  @media (max-width: 937px) {
    width: 122px;
    height: 80px;
  }
}

.headerText {
  font-family: BoldenVan;
  z-index: 4;
  font-style: normal;
  font-weight: normal;
  display: flex;
  justify-content: center;
  font-size: 70px;
  line-height: 90px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #f3ce67;
  text-shadow: 4px 4px 0px #e97133;
  // transition: transform 0.1s ease-out;

  &:hover {
    animation: shake 1s infinite alternate;
  }

  @media (max-width: 937px) {
    margin-top: 7rem;
  }
  @media (max-width: 560px) {
    font-size: 35px;
    line-height: 45px;
  }
}

@keyframes shake {
  0% {
    transform: skewX(-15deg);
  }
  5% {
    transform: skewX(15deg);
  }
  10% {
    transform: skewX(-15deg);
  }
  15% {
    transform: skewX(15deg);
  }
  20% {
    transform: skewX(0deg);
  }
  100% {
    transform: skewX(0deg);
  }
}
