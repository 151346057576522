.wholePageDark {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000d0;
}

.container {
  width: 1066px;
  max-width: 100%;
  height: 608px;
  max-height: 100%;
  position: fixed;
  z-index: 3;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;

  p {
    font-size: 100px;
    font-family: Montserrat;
    justify-self: center;
    color: white;
  }

  span {
    font-size: 30px;
    @media (max-width: 570px) {
      font-size: 24px;
    }
  }

  h2 {
    font-family: Krazy Nights;
    font-style: normal;
    font-weight: normal;
    font-size: 100px;
    line-height: 93px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #ffffff;
    text-shadow: 4px 4px 0px #e97133;
    transform: rotate(-4.7deg);
    margin: auto;
    margin-top: 3rem;

    @media (max-width: 570px) {
      font-size: 50px;
    }
  }
}

.refreshText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
}

.timerText {
  font-size: 30px;
  span {
    font-size: 100px;
    margin-right: 10px;
    @media (max-width: 570px) {
      font-size: 57px;
    }
  }
}
