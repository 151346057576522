.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 165px;

  div {
    max-width: 900px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      position: absolute;
      font-family: Prayuth;
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 145.52%;
      letter-spacing: 0.075em;
      text-transform: capitalize;
      color: #eda664;
    }

    img.walletIcon {
      position: absolute;
      right: 32%;
    }

    div {
      position: absolute;
      right: 11%;
      width: 93px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  img.logo {
    position: absolute;
    left: 0;
    width: 165px;
    height: 165px;
    margin-left: 4%;
  }

  img.menu {
    padding-bottom: 3.3%;
  }

  .icons {
    transition: all 0.1s ease-out;

    &:hover {
      transform: scale(1.1);
    }
  }
}
