$breakpoint-small: 490px;

.wholePageDark {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    linear-gradient(0deg, #973a3e 50.19%, #4e1d3a 100%);
}

.container {
  width: 1066px;
  max-width: 100%;
  height: 483px;
  max-height: 100%;
  position: fixed;
  z-index: 3;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-image: url(../images/modalbg.png);
  background-position: center;
  background-size: cover;
  border-radius: 40px;
  box-shadow: 1px 3px 3px rgb(0 0 0 / 80%);
  overflow-y: auto;
  overflow-x: hidden;
  overflow: hidden;

  @media (max-width: 410px) {
    height: 565px;
  }

  .closeButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 16px;
    top: 21px;
    cursor: pointer;
    background: #ffd767;
    height: 54px;
    width: 54px;
    border-radius: 27px;
    box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.8);
    transition: transform 0.1s ease-out;

    @media (max-width: $breakpoint-small) {
      right: 5px;
      top: 7px;
    }

    &:hover {
      box-shadow: 2px 6px 4px rgba(0, 0, 0, 0.8);
      transform: translateY(-2px);
      background: #ffe395;
    }

    &:active {
      box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.8);
      transform: translateY(1px);
    }
  }

  .closeButton {
  }

  h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 0.5rem;
  }

  .sectionTop {
    max-width: 606px;
    margin: auto;
    padding-bottom: 6rem;
    @media (max-width: $breakpoint-small) {
      padding-bottom: 0rem;
    }

    .errorMsg {
      color: red;
    }

    .awaitingText {
      margin: 0.6rem 0;
      z-index: 3;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      text-transform: uppercase;
      color: white;
      justify-self: center;
    }

    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;

      @media (max-width: 410px) {
        font-size: 16px;
      }
    }
  }

  .sectionBottom {
    align-self: center;
    justify-self: center;
    z-index: 2;
    padding-top: 10rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    p {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 900;
      font-size: 32px;
      line-height: 32%;
      /* or 10px */

      display: flex;
      align-items: center;

      color: #ffffff;

      @media (max-width: 410px) {
        font-size: 16px;
      }
    }

    button {
      margin: 1rem;
      @media (max-width: 410px) {
        margin: 0rem;
      }
    }
    .warningMessage {
      background-color: #00000073;
      border-radius: 40px;
      padding: 1rem;
      h6 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 900;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
      }
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        /* identical to box height, or 133% */

        text-align: center;

        color: #ffffff;
      }
    }
  }

  .imgContainer {
    position: absolute;
    width: 491px;
    bottom: -2rem;
    left: 50%;
    transform: translateX(-50%);

    .bossBear {
    }
  }
}

.connectButton {
  background: #ffd767;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.8);
  border-radius: 27.5px;
  width: 358px;
  height: 74px;
  font-family: Prayuth;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 145.52%;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  color: #000000;
  transition: all 0.1s ease-out;

  &:disabled {
    box-shadow: none;
    background-color: #ffeab0;
    cursor: not-allowed;
    color: grey;

    &:hover {
      box-shadow: none;
      transform: none;
      background-color: #ffeab0;
      cursor: not-allowed;
      color: grey;
    }
  }

  &:hover {
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.8);
    transform: translateY(-2px);
    background: #ffe395;
  }

  &:active {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.8);
    transform: translateY(1px);
  }
}
.anchorEthers {
  text-decoration: underline;
}
