.container {
  height: 100%;
}

.mintText {
  z-index: 4;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.textBox {
  z-index: 2;
  margin: 3rem;
  margin-bottom: 2%;
  max-width: 441px;

  @media (max-width: 490px) {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  .textBold {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    @media (max-width: 490px) {
      font-size: 28px;
      line-height: 28px;
    }
  }

  .subText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 15px;

    @media (max-width: 490px) {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 0.5rem;
    }
  }
}

.connectButton {
  background: #ffd767;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.8);
  border-radius: 27.5px;
  width: 358px;
  height: 74px;
  font-family: Prayuth;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 145.52%;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  color: #000000;
  transition: all 0.1s ease-out;

  @media (max-width: 490px) {
    width: 323px;
    height: 61px;
  }

  &:hover {
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.8);
    transform: translateY(-2px);
    background: #ffe395;
  }

  &:active {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.8);
    transform: translateY(1px);
  }
}

.connectButton2 {
  background: #ffd767;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.8);
  border-radius: 27.5px;
  width: 358px;
  height: 74px;
  font-family: Prayuth;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 145.52%;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  color: #000000;
  transition: all 0.1s ease-out;

  &:hover {
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.8);
    transform: translateY(-2px);
    background: #ffe395;
  }

  &:active {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.8);
    transform: translateY(1px);
  }
}

.modalHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  color: #1b1c2a;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.errorMessage {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 15px;
  color: red;
}

.awaitingText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #1b1b1b;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 600px;
  height: 11px;
  border-radius: 5px;
  background-image: linear-gradient(#ffd767, #ffd767);
  background-size: 0% 100%;
  background-repeat: no-repeat;
  margin-top: 1.2rem;
  @media (max-width: 690px) {
    width: 400px;
  }
  @media (max-width: 410px) {
    width: 325px;
  }
}
input[type="range"]:focus {
  outline: none;
}
// input[type="range"]::-webkit-slider-runnable-track {
//   width: 100%;
//   height: 10px;
//   cursor: pointer;
//   animate: 0.2s;
//   box-shadow: 2px 2px 3px #000000;
//   background: #ffd767;
//   border-radius: 5px;
//   border: 0px solid #000000;
// }
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 2px 2px 3px #000000;
  border: 5px solid white;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background: #ffd767;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3px;
}
// input[type="range"]:focus::-webkit-slider-runnable-track {
//   background: #db0033;
// }

// input[type="range"]::-moz-range-track {
//   width: 100%;
//   height: 10px;
//   cursor: pointer;
//   animate: 0.2s;
//   box-shadow: 2px 2px 3px #000000;
//   background: #ffd767;
//   border-radius: 5px;
//   border: 0px solid #000000;
// }
input[type="range"]::-moz-range-thumb {
  box-shadow: 2px 2px 3px #000000;
  border: 5px solid #ff8585;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background: #ffd767;
  cursor: pointer;
}
// input[type="range"]::-ms-track {
//   width: 100%;
//   height: 10px;
//   cursor: pointer;
//   animate: 0.2s;
//   background: transparent;
//   border-color: transparent;
//   color: transparent;
// }
// input[type="range"]::-ms-fill-lower {
//   background: #ffd767;
//   border: 0px solid #000000;
//   border-radius: 10px;
//   box-shadow: 2px 2px 3px #000000;
// }
// input[type="range"]::-ms-fill-upper {
//   background: none;
//   border: 0px solid #000000;
//   border-radius: 10px;
//   box-shadow: 2px 2px 3px #000000;
// }
// input[type="range"]::-ms-thumb {
//   margin-top: 1px;
//   box-shadow: 2px 2px 3px #000000;
//   border: 5px solid #ff8585;
//   height: 50px;
//   width: 50px;
//   border-radius: 25px;
//   background: #11ff00;
//   cursor: pointer;
// }
// input[type="range"]:focus::-ms-fill-lower {
//   background: #db0033;
// }
// input[type="range"]:focus::-ms-fill-upper {
//   background: #db0033;
// }
