@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "BoldenVan";
  src: local("BoldenVan"), url(./fonts/BoldenVan.ttf) format("truetype");
}

@font-face {
  font-family: "Krazy Nights";
  src: local("krazy-nights"),
    url(./fonts/krazy-nights.regular.ttf) format("truetype");
}

@font-face {
  font-family: "Prayuth";
  src: local("Prayuth"), url(./fonts/PrayuthSlim-Bold.ttf) format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;900&display=swap");
