.container {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.errorText {
  z-index: 4;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 15px;
  color: red;
}

.innerContainer {
  position: relative;
  height: 100%;
  max-width: 100%;
}

.textContainer {
  height: 100%;
  z-index: 3;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;

  @media (max-width: 690px) {
    padding-bottom: 3rem;
  }

  .totalSupplyBox {
    position: absolute;
    top: 208px;
    right: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 20.8929px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.13em;
    color: #ffffff;

    @media (max-width: 400px) {
      letter-spacing: 0.13em;
      right: 0px;
    }

    p:first-of-type {
      font-family: Montserrat;
      letter-spacing: 0.33em;

      @media (max-width: 400px) {
        letter-spacing: 0.13em;
        right: 0px;
        font-size: 14px;
      }
    }

    p {
      text-transform: uppercase;
      font-family: BoldenVan;

      @media (max-width: 400px) {
        letter-spacing: 0.13em;
        right: 0px;
        font-size: 14px;
      }
    }
  }

  .dynamicMintTextBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }

  .dynamicMintText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #ffffff;
  }

  h2 {
    font-family: Krazy Nights;
    font-style: normal;
    font-weight: normal;
    font-size: 100px;
    line-height: 93px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #ffffff;
    text-shadow: 4px 4px 0px #e97133;
    transform: rotate(-4.7deg);
    margin: auto;
    margin-top: 3rem;

    @media (max-width: 937px) {
      margin-top: 6rem;
      font-size: 80px;
      line-height: 73px;
    }

    @media (max-width: 410px) {
      font-size: 59px;
    }
  }

  .header {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 80px;
    line-height: 80px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }

  .buttonText {
    font-family: Prayuth;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 145.52%;
    letter-spacing: 0.075em;
    text-transform: capitalize;
    color: #000000;
  }

  .buttonSubText {
    font-family: Prayuth;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }

  .wltext {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #ffffff;
    margin: 1rem 0;
  }

  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
}

.button {
  background: #ffd767;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.8);
  border-radius: 27.5px;
  width: 358px;
  height: 74px;
  font-family: Prayuth;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 145.52%;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  color: #000000;
  transition: all 0.1s ease-out;

  &:hover {
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.8);
    transform: translateY(-2px);
    background: #ffe395;
  }

  &:active {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.8);
    transform: translateY(1px);
  }
}

.slider {
  width: 400px;
}
